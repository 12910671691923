import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  width: 1.5rem;
  padding: 0;
`;

const ShapeAnimation = ({ position, fill = 'none' }) => (
  <SVG
    className="Header__svg"
    fill={fill}
    viewBox="0 0 1337.97 684.43"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="Header__shape bigSquare"
      fill="#16d5d1"
      d="M546.519 349.271l86.383-56.098 56.097 86.383-86.383 56.098z"
    />
    <path
      className="Header__shape triangle"
      fill="none"
      stroke="#ff4676"
      strokeWidth="8"
      d="M372.15 462.17L321 434.58l-4.88 56.16z"
    />
    <circle
      className="Header__shape bigCircle"
      fill="#ff4676"
      cx="1076.52"
      cy="262.17"
      r="120"
    />
    <path
      className="Header__shape littleSquare"
      fill="#ffe430"
      d="M285.523 262.61l12.372-53.59 53.59 12.372-12.372 53.59z"
    />
    <circle
      className="Header__shape hoop"
      fill="none"
      stroke="#ffe430"
      strokeWidth="13"
      cx="905.52"
      cy="447.17"
      r="45"
    />
    <circle
      className="Header__shape littleCircle"
      fill="#0f1c70"
      cx="1036.52"
      cy="203.17"
      r="27"
    />
    {/* <path
      className="Header__shape bigBlob"
      fill="#0f1c70"
      d="M539.8 137.6c98.3 69 183.5 124 203 198.4 19.3 74.4-27.1 168.2-93.8 245-66.8 76.8-153.8 136.6-254.2 144.9-100.6 8.2-214.7-35.1-292.7-122.5S-18.1 384.1 7.4 259.8C33 135.6 126.3 19 228.5 2.2c102.1-16.8 213.2 66.3 311.3 135.4z"
    ></path> */}
  </SVG>
);

export default ShapeAnimation;
