import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import MEDIA from '../helpers/mediaTemplates';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import Gallery from 'components/gallery';
import ShapeAnimation from '../images/ShapeAnimation';
import mail from '../images/mail.png';
import linkedin from '../images/linkedin.png';

const bigSquare = keyframes`
  0% { transform: translateY(10%) rotate(-80deg) scale(0); }
  100% { transform: translateY(0) rotate(0deg) scale(1); }
`;

const littleSquare = keyframes`
  0% { transform: translate(226%, 183%) rotate(140deg) scale(0) ; }
  100% { transform: translate(0%, 0%) rotate(0deg) scale(1); }
`;

const triangle = keyframes`
  0% { transform: rotate(-140deg) scale(0) ; }
  100% { transform: rotate(0deg) scale(1); }
`;

const hoop = keyframes`
  0% { transform: translate(-160%, -33%) scale(0) ; }
  100% { transform: translate(0%, 0%) scale(1); }
`;

const bigCircle = keyframes`
  0% { transform: scale(0) translate(60%, 3%); }
 100% { transform: scale(1) translate(0%, 0%); }
`;

const littleCircle = keyframes`
  0% { transform: scale(0) }
  100% { transform: scale(1) }
`;

const bigBlob = keyframes`
  0%   { transform: scale(1)   translate(10px, -30px); }
  38%  { transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg); }
  40%  { transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg); }
  78%  { transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg); }
  80%  { transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg); }
  100% { transform: scale(1)   translate(10px, -30px); }
`;

const Index = ({ data }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: [0, 1],
      transition: {
        delay: 0.5,
        ease: 'easeOut',
        duration: 3,
      },
    },
  };

  return (
    <Animate
      initial="hidden"
      animate="visible"
      variants={variants}
      onMouseMove={(e) => setPosition({ x: e.pageX, y: e.pageY })}
    >
      <Layout>
        <>
          <Hero>
            {/* <ImgWrapper> */}
              <AnimationContainer className="Header">
                <ShapeAnimation position={position} />
              </AnimationContainer>
              <TempImg
                style={{
                  transform: `translate(${position.x / 20}px, ${
                    position.y / 20
                  }px)`,
                }}
              />
            {/* </ImgWrapper> */}
          </Hero>
          <Content>
            <Description 
                dangerouslySetInnerHTML={{
                __html: data.homeJson.content.childMarkdownRemark.html,
              }}>
              {/* I’m a User Experience Researcher & Software Developer, inspired by everyday people, technology, and the pursuit of a greater good. */}
            </Description>
          </Content>
        </>
        <Gallery items={data.homeJson.gallery} />
        {/* <div style={{ height: '50vh' }} /> */}
        <Footer>
          <FooterText>Lets Connect</FooterText>
          <FooterIcon>
            <a href="https://www.linkedin.com/in/wyrd00/">
              <Linkedin src={linkedin} alt="linkedinIcon" />
            </a>
            <a href="mailto:amysheree0@gmail.com?subject=Hello!">
              <img src={mail} alt="emailIcon" />
            </a>
          </FooterIcon>
        </Footer>
        {/* <IOExample /> */}
      </Layout>
    </Animate>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

const Description = styled.div`
  text-align: left;
  width: 80vw;
  font-size: 2.2em;
  margin: 1em auto -4em;
  line-height: 1.5;
  font-weight: 400;

  ${MEDIA.TABLET`
    font-size: 1.5em;
    margin: 8em auto -15em;
  `}
`

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  -webkit-box-pack: justify;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 0px;
  border-color: #eee;
  background-color: white;

  img {
    height: 48px;
    padding: 0 10px;
  }
`;

const FooterText = styled.h2`
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-family: Avenirnext, sans-serif;
  color: #1a1b1d;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
`;

const FooterIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Linkedin = styled.img`
  height: 36px !important;
`;

const Animate = styled(motion.div)`
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  width: 100%;
`;

const Hero = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 0;
  pointer-events: none;
  display: block;
  z-index: -10;
`;

const AnimationContainer = styled.div`
  .Header__shape {
    animation-duration: 4s;
    animation-timing-function: cubic-bezier(0.18, 1.17, 0.03, 1.46);
    animation-fill-mode: backwards;
    transform-origin: center;
    // this here is the good stuff
    transform-box: fill-box;
  }

  .Header {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Header__svg {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    will-change: transform;
  }

  .Header__title {
    font-family: Avenir, Futura, 'Open Sans', 'Gill Sans', 'Helvetica Neue',
      Ariel, sans-serif;
    font-weight: bold;
    font-size: 6vw;
    margin: 0;
  }

  .bigSquare {
    animation: 3s ${bigSquare};
  }

  .littleSquare {
    animation-name: ${littleSquare};
  }

  .triangle {
    animation-name: ${triangle};
  }

  .hoop {
    animation-name: ${hoop};
  }

  .bigCircle {
    animation-name: ${bigCircle};
  }

  .littleCircle {
    animation-name: ${littleCircle};
  }

  .bigBlob {
    animation: 10s ${bigBlob} infinite;
  }

  @for $i from 1 to 12 {
    .Header__shape:nth-child(#{$i}) {
      animation-delay: $i * 0.16s;
    }
  }
`;
// const ImgWrapper = styled.div`
//   transform-style: preserve-3d;
//   backface-visibility: hidden;
//   position: relative;
//   pointer-events: none;
//   background: radial-gradient(#c2c2c2 8%, transparent 8%), white;
//   background-position: 0 0, 25px 25px;
//   background-size: 25px 25px;
// `;

const TempImg = styled.div`
  border: 0px none !important;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  position: relative;
  display: block;
  height: 100vh;
  z-index: 10;
`;

const Content = styled(motion.section)`
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
  max-width: 100%;
  transition: all 0s linear;
  -moz-transition: all 0s linear;
  -webkit-transition: all 0s linear;
  -o-transition: all 0s linear;
  text-align: center !important;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  padding-top: 48vh;
  line-height: 2em;
`;

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        slug
        copy
        image {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
