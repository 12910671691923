import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Container = styled.div`
  // display: -ms-flexbox;
  // overflow: hidden;
  // margin-right: 0px;
  // padding-right: 0px;
  // -webkit-box-orient: vertical;
  // -webkit-box-direction: normal;
  // -webkit-flex-direction: column;
  // -ms-flex-direction: column;
  // flex-direction: column;
  // -ms-flex-align: stretch;
  // border-style: none;
  // border-width: 1px;
  // border-color: rgba(92, 92, 92, 0.3);
  // border-radius: 4px;
  // box-shadow: 0 -2px 16px 0 rgba(0, 0, 0, 0.05);
  // -webkit-transition: box-shadow 200ms cubic-bezier(.215, .61, .355, 1), -webkit-transform 200ms ease;
  // transition: box-shadow 200ms cubic-bezier(.215, .61, .355, 1), transform 200ms ease;

  ${MEDIA.MIN_TABLET`
    display: block;
    width: 75%;
    transform: translateX(15%);
  `};
`;

export const GalleryImg = styled(Img)`
  max-height: 600px;
`

export const Title = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 2rem 1rem;
`;

export const Copy = styled.p`
  color: #757575;
  margin: 0 2rem 2rem;

  ${MEDIA.TABLET`
    margin-bottom: 4rem;
  `};
`;

export const Tags = styled.span`

`
