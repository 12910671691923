import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { GalleryImg, Container, Title, Copy } from './item.css';

const Item = ({ title, copy, image, slug }) => (
  <Container>
    <Link to={slug}>
      <figure>
        <GalleryImg fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
        <figcaption>
          <Title>{title}</Title>
          <Copy>{copy}</Copy>
        </figcaption>
      </figure>
    </Link>
  </Container>
);

Item.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object.isRequired,
  slug: PropTypes.string,
};

export default Item;
