import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // grid-template: 25vw;
  grid-gap: 4rem;
  padding: 4rem;
  background-color: white;

  > :nth-child(5) {
    align-self: end;
  }

  ${MEDIA.TABLET`
    display: block;

    // &::after {
    //   content: '';
    //   flex: 0 0 49%;
    // }
  `};

  //   ${MEDIA.PHONE`
//   display: block;
// `};
`;
